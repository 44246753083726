import React, { ReactElement } from 'react'

import { Heading, Lead, Text } from '@te-digi/styleguide'

import { Head } from './Head'

interface HeaderProps {
  heading?: string
  lead?: string
  status?: ReactElement
  subHeading?: string
}

const StyleGuideHeader = ({
  heading,
  lead,
  status,
  subHeading
}: HeaderProps) => (
  <>
    <Head title={heading} />
    <Heading
      noMargin={!lead}
      subHeading={subHeading}
    >
      <Text color="primary">{heading}</Text>
    </Heading>
    {lead && <Lead noMargin>{lead}</Lead>}
    {status}
  </>
)

export { StyleGuideHeader }
